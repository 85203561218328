import * as THREE from 'three';
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass.js";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader.js";
import { TextGeometry } from "three/addons/geometries/TextGeometry.js";
import gsap from "gsap";
import "../static/styles/launch.css";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass.js";
import { VignetteShader } from "three/examples/jsm/shaders/VignetteShader.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";

const manager = new THREE.LoadingManager();

let currentWidth = 0;
let targetWidth = 0;

// Optional: show the progress as items load
manager.onProgress = function (url, itemsLoaded, itemsTotal) {
  targetWidth = (itemsLoaded / itemsTotal * 100);
};


manager.onLoad = function () {
  console.log('All assets loaded.');
  targetWidth = 100;  // Ensure target width is 100% when all assets are loaded
  document.getElementById('enterScene').style.display = 'flex';

};


function updateProgressBar() {
  if (Math.abs(currentWidth - targetWidth) > 0.1 || currentWidth < 99.5) {
      // Interpolate towards the target width
      currentWidth += (targetWidth - currentWidth) * 0.05;

      // Update the progress bar width
      document.querySelector('.progress-value').style.width = currentWidth + "%";
      console.log(`Current Width: ${currentWidth}%`);
      if (currentWidth > 99.5) {
        document.getElementById('loading-screen').style.display = 'none';
      }

      // Continue the animation loop
      requestAnimationFrame(updateProgressBar);
  }
}


// Start the animation loop
updateProgressBar();


const canvas = document.querySelector("canvas.webgl");
let scene = new THREE.Scene();

// Inital Scene Background
scene.background = new THREE.Color(0x787878);
const textureLoader = new THREE.TextureLoader();

let postCard_model;
let knight_model;
let skateboard_model;
let car_model;

// Sizes
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

const mouse = new THREE.Vector2();
const raycaster = new THREE.Raycaster();
let camera = new THREE.PerspectiveCamera(
  35,
  sizes.width / sizes.height,
  0.1,
  100
);
camera.position.z = 7;
let cameraTarget = new THREE.Vector3(0, 0, 0); // Set to original position initially
const rotationSpeed = 0.015;
const damping = 0.9; // or whatever value you want for damping

const cursor = { x: 0, y: 0 };
const maxRotationX = THREE.MathUtils.degToRad(4);
const maxRotationY = THREE.MathUtils.degToRad(4);

const targetRotation = { x: 0, y: 0 };
const currentRotation = { x: 0, y: 0 }; // Added currentRotation

const lerp = (start, end, t) => {
  return start * (1 - t) + end * t;
};

window.addEventListener("mousemove", (event) => {
  cursor.x = (event.clientX / sizes.width) * 2 - 1;
  cursor.y = -(event.clientY / sizes.height) * 2 + 1;

  targetRotation.x = cursor.y * maxRotationX;
  targetRotation.y = -cursor.x * maxRotationY;
});

// Renderer
const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
  alpha: true,
  antialias: true,
});
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
renderer.gammaFactor = 2.2;
renderer.gammaOutput = true;
renderer.toneMapping = THREE.ACESFilmicToneMapping;

let targetQuaternion = new THREE.Quaternion();
let dampingFactor = 0.05;
// Post-processing
const composer = new EffectComposer(renderer);
const renderPass = new RenderPass(scene, camera);

const grainShader = {
    uniforms: {
        "tDiffuse": { type: "t", value: null },
        "amount": { type: "f", value: 0.05 }
    },

    vertexShader: [
        "varying vec2 vUv;",
        "void main() {",
        "vUv = uv;",
        "gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );",
        "}"
    ].join("\n"),

    fragmentShader: [
        "uniform sampler2D tDiffuse;",
        "uniform float amount;",
        
        "varying vec2 vUv;",
        
        "float rand(vec2 co){",
        "return fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453);",
        "}",
        
        "void main() {",
        "vec4 color = texture2D(tDiffuse, vUv);",
        "float grain = rand(vUv + vec2(rand(vUv), rand(vUv))) * amount;",
        "gl_FragColor = vec4(color.rgb + grain, color.a);",
        "}"
    ].join("\n")
};


composer.addPass(renderPass);
const bloomPass = new UnrealBloomPass(
  new THREE.Vector2(window.innerWidth, window.innerHeight),
  1.5,
  0.4,
  0.85
);
bloomPass.threshold = 0.7;
bloomPass.strength = 0.6;
bloomPass.radius = 1;
composer.addPass(bloomPass);

const vignettePass = new ShaderPass(VignetteShader);
vignettePass.uniforms["offset"].value = 0.95; // Adjust the offset value as needed (e.g., 0.95)
vignettePass.uniforms["darkness"].value = 1.5; // Adjust the darkness value as needed (e.g., 1.5)
composer.addPass(vignettePass);

const grainPass = new ShaderPass(grainShader);
grainPass.renderToScreen = true;  // If it's your last pass
composer.addPass(grainPass);


function isCollision(object1, object2) {
  // Check if the objects are valid
  if (!object1 || !object2 || !object1.geometry || !object2.geometry) {
    return false;
  }

  const box1 = new THREE.Box3().setFromObject(object1);
  const box2 = new THREE.Box3().setFromObject(object2);
  return box1.intersectsBox(box2);
}

let currentScene = "knight"; // This will represent the current scene context.

// Model loader
const loader = new GLTFLoader(manager);
let carMixer;
let mixer;
let welcomeMesh;
const fontLoader = new FontLoader();

loader.load("./models/car/scene.gltf", (gltf) => {
    car_model = gltf.scene;
    car_model.scale.set(1, 1, 1);
    car_model.position.set(-2, -1, 13);
    // Rotate model horizontally
    car_model.rotation.y = degreesToRadians(0);
  scene.add(car_model);
  car_model.visible = false; // Hide the giant_model initially
  carMixer = new THREE.AnimationMixer(car_model);

      // Play all animations
      gltf.animations.forEach((clip) => {
        const action = carMixer.clipAction(clip);
        action.setLoop(THREE.LoopPingPong); // Set the loop mode to ping-pong
        action.play();
    });
    
});

const postCardState = {
    isDragging: false,
    previousMousePosition: { x: 0, y: 0 },
    targetQuaternion: new THREE.Quaternion(),
    currentSpeed: { x: 0, y: 0 }
  };

  const skateboardState = {
    isDragging: false,
    previousMousePosition: { x: 0, y: 0 },
    targetQuaternion: new THREE.Quaternion(),
    currentSpeed: { x: 0, y: 0 }
  };

loader.load("./models/postCard/scene.gltf", (gltf) => {
    postCard_model = gltf.scene;
    postCard_model.scale.set(0.006, 0.006, 0.006);
    postCard_model.position.set(-10, -2, -50);
    postCard_model.rotation.y = degreesToRadians(120);
    postCardState.targetQuaternion = postCard_model.quaternion.clone();

    renderer.domElement.addEventListener("mousedown", (e) => {
        postCardState.isDragging = true;
        postCardState.previousMousePosition = {
            x: e.offsetX,
            y: e.offsetY,
        };
    });

    renderer.domElement.addEventListener("mouseup", () => {
        postCardState.isDragging = false;
    });

    renderer.domElement.addEventListener("mousemove", (e) => {
        if (!postCardState.isDragging) return;

        const deltaMove = {
            x: e.offsetX - postCardState.previousMousePosition.x,
            y: e.offsetY - postCardState.previousMousePosition.y,
        };

        postCardState.currentSpeed.x = deltaMove.x;
        postCardState.currentSpeed.y = deltaMove.y;

        const rotationQuaternion = new THREE.Quaternion().setFromEuler(
            new THREE.Euler(
                deltaMove.y * rotationSpeed,
                deltaMove.x * rotationSpeed,
                0,
                "YXZ" // this ordering can help prevent gimbal lock
            )
        );

        postCardState.targetQuaternion.multiplyQuaternions(
            rotationQuaternion,
            postCard_model.quaternion
        );
        postCardState.previousMousePosition = {
            x: e.offsetX,
            y: e.offsetY,
        };
    });

    scene.add(postCard_model);
    postCard_model.visible = false;
});



let lightsAdded = false;


  
loader.load("./models/knight_launch/scene.gltf", (gltf) => {
  knight_model = gltf.scene;
  knight_model.position.y = -0.9;
  knight_model.scale.set(1, 1, 1);

  mixer = new THREE.AnimationMixer(knight_model);
  gltf.animations.forEach((clip) => {
    mixer.clipAction(clip).play();
  });

  scene.add(knight_model);
  if (knight_model) {
    knight_model.visible = true;
  }
  loadKnightScene();
});

let isButtonInitialized = false;
let button;
let isTitleInitialized = false;
let isPatternsInitialized = false;
let welcomeMaterial;
let sprites = [];
let sprite;
let edges;
let text;
let boxHelper;
function loadKnightScene() {
  vignettePass.uniforms["offset"].value = 0.95; // Adjust the offset value as needed (e.g., 0.95)
  vignettePass.uniforms["darkness"].value = 1.5; // Adjust the darkness value as needed (e.g., 1.5)
  intersects = [];
  currentScene = "knight";
  knight_model.visible = true; // Show the knight
  camera.position.set(0, 0, 7);
    // Inital Scene Background
    scene.background = new THREE.Color(0x787878);
    bloomPass.strength = 0.4;
  if (skateboard_model) {
    skateboard_model.visible = false;
  }
  if (postCard_model) {
    postCard_model.visible = false;
  }

  if (car_model) {
    car_model.visible = false;
} 

  if (isButtonInitialized) {
    knight_model.add(button); // Add the button back to the knight model
  }

  // Enter Button
  if (!isButtonInitialized) {
    const geometry = new THREE.BoxGeometry(1, 0.3, 0.2);
    const material = new THREE.MeshBasicMaterial({
      transparent: true,
      opacity: 0.0,
    });
    button = new THREE.Mesh(geometry, material);
    button.position.z = 1;
    button.position.y = -0.4;

    const edgeGeometry = new THREE.EdgesGeometry(geometry);
    const edgeMaterial = new THREE.LineBasicMaterial({
      color: 0xffffff,
      linewidth: 2,
    });
    edges = new THREE.LineSegments(edgeGeometry, edgeMaterial);
    button.add(edges);

    knight_model.add(button);

    boxHelper = new THREE.BoxHelper(button, 0xff0000);
    // Adjust the BoxHelper's position to match the button's position
    boxHelper.position.set(
      button.position.x,
      button.position.y,
      button.position.z
    );
    boxHelper.visible = false; // Hide the BoxHelper initially
    knight_model.add(boxHelper);

    isButtonInitialized = true;
  }

  // Welcome Title
  if (!isTitleInitialized) {
    const welcomeTexture = textureLoader.load("./images/welcome.png");

    const welcomeGeometry = new THREE.PlaneGeometry(2, 1); // Adjust size as needed
    welcomeMaterial = new THREE.MeshBasicMaterial({
      map: welcomeTexture,
      transparent: true,
      side: THREE.DoubleSide,
    });
    welcomeMaterial.opacity = 0; // Initial opacity set to 0
    const welcomeMesh = new THREE.Mesh(welcomeGeometry, welcomeMaterial);
    welcomeMesh.position.set(0, 1.7, 1);
    knight_model.add(welcomeMesh);

    // Fade-in animation
    gsap.to(welcomeMaterial, { opacity: 1, duration: 2, delay: 0.5 });

    fontLoader.load("./font/Baskerville.json", (font) => {
      const textGeometry = new TextGeometry("Enter Spacebar", {
        font: font,
        size: 0.1,
        height: 0.2,
      });

      textGeometry.computeBoundingBox();

      const welcome_Text_Material = new THREE.MeshBasicMaterial({
        color: 0xffffff,
      });

      text = new THREE.Mesh(textGeometry, welcome_Text_Material);

      button.add(text);

      text.position.x = -textGeometry.boundingBox.max.x * 0.5;
      text.position.y = -textGeometry.boundingBox.max.y * 0.5;
    });
    isTitleInitialized = true;
  }
  // Fade-in animation
  gsap.to(welcomeMaterial, { opacity: 1, duration: 2, delay: 0.5 });

  // Patterns
  if (!isPatternsInitialized) {
    const pattern1Texture = textureLoader.load("./images/pattern1.png");
    const pattern2Texture = textureLoader.load("./images/pattern2.png");
    const pattern3Texture = textureLoader.load("./images/pattern3.png");

    const patternTextures = [pattern1Texture, pattern2Texture, pattern3Texture];
    const numberOfSprites = 50;
    const minZ = 10;  // Adjust as needed
    const maxZ = 20;  // Adjust as needed
    for (let i = 0; i < numberOfSprites; i++) {
      let isColliding = false;
      let attempts = 0;

      do {
        // Randomly select one of the three textures for this sprite
        const randomTexture =
          patternTextures[Math.floor(Math.random() * patternTextures.length)];

        // Create a material with the random texture
        const spriteMaterial = new THREE.SpriteMaterial({
          map: randomTexture,
          transparent: true,
          opacity: 0.5,
          color: 0xffffff,
          rotation: 0,
        });

        sprite = new THREE.Sprite(spriteMaterial);

        // Position
        sprite.position.x = (Math.random() - 0.5) * 9; // Random x position
        sprite.position.y = (Math.random() - 0.5) * 5; // Random y position
        sprite.position.z = (Math.random() - 0.5) * 20; // Random z position

        // Size
        sprite.scale.set(0.5, 0.5, 0.5); // Adjust as needed

        // Check for collisions with the button and model
        isColliding =
          isCollision(sprite, button) || isCollision(sprite, knight_model);

        // If colliding, remove the sprite and try again with a new position
        if (isColliding) {
          scene.remove(sprite);
        } else {
          scene.add(sprite);
        }

        attempts++;

        // Limit the number of attempts to prevent infinite loops
        if (attempts > 100) {
          console.warn(
            "Could not find a non-colliding position for the pattern."
          );
          break;
        }
        sprite.userData.initialPosition = sprite.position.clone();
        sprites.push(sprite);

        // Add the sprite to the model
        knight_model.add(sprite);
      } while (isColliding);
      isPatternsInitialized = true;
    }
  }
  document.getElementById('projects').style.display = 'none';
  document.getElementById('aboutPanel').style.display = 'none';
  document.getElementById('contactPanel').style.display = 'none';

}

let characters = []; // This will hold the Mesh objects for each character

const softBlurTexture = new THREE.TextureLoader().load("./images/softBlur.png");
// 1. Create an array of sentences you'd like to display.

// 3. Implement a flag to ensure the animation only shows once.
let sentencesDisplayed = false;
const minY = -100; // The bottom of the tree line
const maxY = 200; // The top of the tree line



function randomPosition(min, max) {
  return Math.random() * (max - min) + min;
}

let buttonGroup;
let aboutText;
let intersects = [];

loader.load("./models/skateboard/scene.gltf", (gltf) => {
    skateboard_model = gltf.scene;
    skateboard_model.scale.set(1, 1, 1);
    skateboard_model.position.set(1, -1, 2.5);
    skateboardState.targetQuaternion = skateboard_model.quaternion.clone();

    renderer.domElement.addEventListener("mousedown", (e) => {
      skateboardState.isDragging = true;
      skateboardState.previousMousePosition = {
        x: e.offsetX,
        y: e.offsetY,
      };
    });
  
    renderer.domElement.addEventListener("mouseup", () => {
      skateboardState.isDragging = false;
    });
  
    renderer.domElement.addEventListener("mousemove", (e) => {
      if (!skateboardState.isDragging) return;
  
      const deltaMove = {
        x: e.offsetX - skateboardState.previousMousePosition.x,
        y: e.offsetY - skateboardState.previousMousePosition.y,
      };
  
      skateboardState.previousMousePosition = {
        x: e.offsetX,
        y: e.offsetY,
      };
  
      const rotationQuaternion = new THREE.Quaternion().setFromEuler(
        new THREE.Euler(
          deltaMove.y * rotationSpeed,
          deltaMove.x * rotationSpeed,
          0,
          "YXZ"
        )
      );
  
      skateboardState.targetQuaternion.multiplyQuaternions(
        rotationQuaternion,
        skateboard_model.quaternion
      );
    });

    scene.add(skateboard_model);
    skateboard_model.visible = false;
});





function loadSkateboardScene() {
    // Update the scene directly without any vignette transition
    currentScene = "skateboard";
    if (knight_model) {
    knight_model.visible = false;
    }
    if (car_model) {
        car_model.visible = false;
    } 
    postCard_model.visible = false;
    skateboard_model.visible = true;
    camera.position.set(0, 0, 7);
    cameraTarget = skateboard_model.position;
    skateboard_model.rotation.y = degreesToRadians(0);
    vignettePass.uniforms["offset"].value = 0; // Adjust the offset value as needed (e.g., 0.95)
    vignettePass.uniforms["darkness"].value = 0; // Adjust the darkness value as needed (e.g., 1.5)
    bloomPass.strength = 0.1;
    grainPass.uniforms["amount"].value = 0.1;  // Adjust this value as you see fit
    if (lightsAdded === false) {
        const skateLight = new THREE.PointLight(0xffffff, 1);
        skateLight.position.set(0, 0, 0);
        skateboard_model.add(skateLight);

        const ambientLight = new THREE.AmbientLight(0xffffff, 3);
        skateboard_model.add(ambientLight);
        lightsAdded = true;
    }

    scene.background = new THREE.Color("#fbf1d5");
    document.getElementById('projects').style.display = 'none';
    document.getElementById('aboutPanel').style.display = 'block';
    document.getElementById('aboutPanel').style.left = '0px';
    document.getElementById('contactPanel').style.display = 'none';

}

function degreesToRadians(degrees) {
  return degrees * (Math.PI / 180);
}


function loadPostCardScene() {
    grainPass.uniforms["amount"].value = 0.1;  // Adjust this value as you see fit

    document.getElementById('projects').style.right = '0px'; // Show panel
    document.getElementById('projects').style.display = 'block';
    document.getElementById('aboutPanel').style.display = 'none';
    document.getElementById('contactPanel').style.display = 'none';

    currentScene = "postCard";
    
    // Vignette
    vignettePass.uniforms["offset"].value = 0;
    vignettePass.uniforms["darkness"].value = 0;
        

    // CSS
    document.body.style.filter = "none";

    // Other effects
    // composer.passes.forEach(pass => {
    //     pass.enabled = false;
    // });
    // Enable only the necessary passes
    postCard_model.position.set(-10, -2, -50);
    postCard_model.rotation.y = degreesToRadians(120);
    camera.position.set(0, 0, 7);
    camera.up.set(0, 1, 0);

    if (knight_model) {
        knight_model.visible = false;
    }
    if (skateboard_model) {
        skateboard_model.visible = false;
    } 
    if (car_model) {
        car_model.visible = false;
    } 
    
    postCard_model.visible = true;

    bloomPass.strength = 0.0;

    scene.background = new THREE.Color("#fbf1d5");
}



renderer.domElement.addEventListener('dblclick', onDoubleClick, false);

function onDoubleClick(event) {
    // First, check if the postCard_model is visible
    if (postCard_model && postCard_model.visible) {

        // Convert the mouse position to normalized device coordinates (-1 to +1).
        mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

        raycaster.setFromCamera(mouse, camera);

        // Check if the postCard_model is intersected
        const intersects = raycaster.intersectObject(postCard_model);

        if (intersects.length > 0) {
            // If postCard_model is double-clicked, animate its position.
            postCard_model.position.z += 5;
            gsap.to(postCard_model.position, {
                duration: 2, // Animation duration in seconds, adjust as needed
                ease: "power2.out", // Easing function for the animation
                x: -2,
                y: -1,
                z: -10
            });
        }
    }
}

function loadCarScene() {
    grainPass.uniforms["amount"].value = 0.05;  // Adjust this value as you see fit

  currentScene = "car";
  vignettePass.uniforms["darkness"].value = 0.95;
  // disable all effects for this scene
    vignettePass.uniforms["offset"].value = 0.95; // Adjust the offset value as needed (e.g., 0.95)
    vignettePass.uniforms["darkness"].value = 1; // Adjust the darkness value as needed (e.g., 1.5)
    bloomPass.strength = 0.5;

  camera.position.set(0, 0, 20);
  camera.up.set(0, 1, 0); // Ensure the camera's up vector aligns with the world's Y-axis
  const ambientLight = new THREE.AmbientLight(0xffffff, 10);
  car_model.add(ambientLight);
    const pointLight = new THREE.PointLight(0xffffff, 100);
    pointLight.position.set(0, 0, 30);
    pointLight.distance = 50;   // Increase this to make the light affect a larger area
    pointLight.decay = 5;       // Adjust this to control the falloff of the light
    car_model.add(pointLight);
  if (knight_model) {
    knight_model.visible = false;
  }
  skateboard_model.visible = false; // Hide the skateboard_model
  postCard_model.visible = false;
  if (car_model) {
    car_model.visible = true;
    // ambient light
    const ambientLight = new THREE.AmbientLight(0xffffff, 1);
    scene.add(ambientLight);
    bloomPass.strength = 0.3;
  }

  document.getElementById('projects').style.display = 'none';
  document.getElementById('aboutPanel').style.display = 'none';
  document.getElementById('contactPanel').style.display = 'block';

  scene.background = new THREE.Color("#2B3D51");
}

function welcomePosition() {
    loadKnightScene();
}

function contactPosition() {
  loadCarScene();
}

function aboutPosition() {
  loadSkateboardScene();
}

function projectsPosition() {
  loadPostCardScene();
}

document.getElementById("welcomeButton").addEventListener("click", function () {
  welcomePosition();
  event.currentTarget.blur();
});
document.getElementById("aboutButton").addEventListener("click", function () {
  aboutPosition();
  event.currentTarget.blur();
});
document
  .getElementById("projectsButton")
  .addEventListener("click", function () {
    projectsPosition();
    event.currentTarget.blur();
  });
document.getElementById("contactButton").addEventListener("click", function () {
  contactPosition();
  event.currentTarget.blur();
});

let windowHeight = window.innerHeight;
window.addEventListener("resize", function () {
  windowHeight = window.innerHeight;
});

let virtualScrollPosition = 0;


window.addEventListener('click', (event) => {
    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
    raycaster.setFromCamera(mouse, camera);
    const intersects = raycaster.intersectObject(skateboard_model);
});

function animateOverlay(callback) {
    // Knight to Skateboard (Black Screen Animation)
    if (currentScene === "knight") {
        const blackScreen = document.createElement("div");
        blackScreen.classList.add("blackScreen");
        document.body.appendChild(blackScreen);
    
        setTimeout(() => {
            blackScreen.style.bottom = "0";
        }, 25); // Reduced from 50ms to 25ms
    
        setTimeout(() => {
            callback();
        }, 1000); // Reduced from 1500ms to 750ms
    
        setTimeout(() => {
            blackScreen.style.bottom = "100vh";
        }, 1150); // Reduced from 1650ms to 825ms
        
        setTimeout(() => {
            blackScreen.remove();
        }, 2150);  // Reduced from 2650ms to 1325ms
    }
    
    
    // Skateboard to Postcard (Red Screen Animation)
    else if (currentScene === "skateboard") {
        const redScreen = document.createElement("div");
        redScreen.classList.add("redScreen");
        document.body.appendChild(redScreen);

        setTimeout(() => {
            redScreen.style.bottom = "0";
        }, 50);

        setTimeout(() => {
            callback();
        }, 1050);

        setTimeout(() => {
            redScreen.style.bottom = "100vh";
        }, 1150);
        
        setTimeout(() => {
            redScreen.remove();
        }, 2150);
    }
    else if (currentScene === "postCard") {
        const yellowScreen = document.createElement("div");
        yellowScreen.classList.add("yellowScreen");
        document.body.appendChild(yellowScreen);

        setTimeout(() => {
            yellowScreen.style.bottom = "0";
        }, 50);

        setTimeout(() => {
            callback();
        }, 1050);

        setTimeout(() => {
            yellowScreen.style.bottom = "100vh";
        }, 1150);
        
        setTimeout(() => {
            yellowScreen.remove();
        }, 2150);
    }
}

document.querySelectorAll('.project').forEach(project => {
    project.addEventListener('click', function() {
        const content = this.querySelector('.project-content');
        const description = this.querySelector('.project-description');

        // If content is visible, hide it and show description
        if (getComputedStyle(content).transform === 'none') {
            content.style.transform = 'translateX(-100%)'; // Move content to the left
            description.style.transform = 'translateX(0)';  // Move description into view
        } else {
            content.style.transform = 'none'; // Reset content position
            description.style.transform = 'translateX(100%)'; // Move description off to the right
        }
    });
});

let enterSceneDisplayed = true;  // This will track whether the enterScene page is


window.addEventListener("keydown", function (event) {
  // Check if the pressed key is the spacebar
  if (event.code === "Space") {
    if (enterSceneDisplayed) {
      // If enterScene is still displayed, just hide it and set the flag to false.
      let enterSceneElement = document.getElementById('enterScene');
      enterSceneElement.style.opacity = '0';

      // After the transition duration (0.5s in this case), hide the enterScene page completely.
      setTimeout(() => {
        enterSceneElement.style.display = 'none';
        enterSceneDisplayed = false;
      }, 500);

    } else {
      // Use the animateOverlay function to handle scene changes with a transition effect
      animateOverlay(() => {
        switch (currentScene) {
          case "knight":
            loadSkateboardScene();
            break;
          case "skateboard":
            loadPostCardScene();
            break;
          case "postCard":
            loadCarScene();
            break;
          case "car":
            loadKnightScene();  // Loop back to the start after the car scene
            break;
          default:
            // Handle any unexpected cases
            console.error("Unexpected scene:", currentScene);
            break;
        }
      });
    }
  }
});


let enterClicked = false;

// Handle window resize
window.addEventListener("resize", () => {
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();

  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

function applyInertiaEffect(model, state) {
    if (!model.visible) return;

    if (!state.isDragging && (state.currentSpeed.x !== 0 || state.currentSpeed.y !== 0)) {
        const rotationQuaternion = new THREE.Quaternion().setFromEuler(
            new THREE.Euler(
                state.currentSpeed.y * rotationSpeed,
                state.currentSpeed.x * rotationSpeed,
                0,
                "YXZ"
            )
        );

        state.targetQuaternion.multiplyQuaternions(rotationQuaternion, model.quaternion);

        // Apply damping to slow down the rotation over time
        state.currentSpeed.x *= damping;
        state.currentSpeed.y *= damping;

        // Stop the rotation when speed is too low
        if (Math.abs(state.currentSpeed.x) < 0.1) state.currentSpeed.x = 0;
        if (Math.abs(state.currentSpeed.y) < 0.1) state.currentSpeed.y = 0;
    }

    model.quaternion.slerp(state.targetQuaternion, dampingFactor);
}

// Animation loop
function animate() {
  const time = Date.now() * 0.001; 
  const hoverSpeed = 2;
  const hoverDistance = 0.1;

  if (postCard_model) {
    applyInertiaEffect(postCard_model, postCardState);
  }

  if (skateboard_model) {
    applyInertiaEffect(skateboard_model, skateboardState);
  }

  // Apply hover effect to each sprite
  sprites.forEach((sprite) => {
    sprite.position.y =
      sprite.userData.initialPosition.y +
      Math.sin(time * hoverSpeed) * hoverDistance;
  });

  if (aboutText) {
    aboutText.lookAt(camera.position);
  }

  if (mixer) {
    mixer.update(0.01);
  }

  if (carMixer) {
    carMixer.update(0.01);
  }

  if (buttonGroup) {
    buttonGroup.lookAt(camera.position);
  }

  if (button) {
    button.lookAt(camera.position);
  }

  // Check if the characters are loaded and then update their properties if needed
  if (characters.length > 0) {
    // For now, we're just fading them in, but you can add more logic if needed.
    characters.forEach((charMesh) => {
      if (charMesh.material.opacity < 1) {
        charMesh.material.opacity += 0.01; // Increment opacity for a fade-in effect
      }
    });
  }

  // Camera animation based on cursor position
  // Define the maximum rotation angles (in radians) for the parallax effect
  const lerpFactor = 0.1; // Adjust this value to make the transition faster or slower
  camera.rotation.x = THREE.MathUtils.lerp(
    camera.rotation.x,
    targetRotation.x,
    lerpFactor
  );
  camera.rotation.y = THREE.MathUtils.lerp(
    camera.rotation.y,
    targetRotation.y,
    lerpFactor
  );

  // Render using the composer
  composer.render();
  requestAnimationFrame(animate);

}
animate();
